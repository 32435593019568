<template>
  <div class="">
    <el-form
      class="add-form"
      @submit.prevent="checkContractInformation"
      @keydown.enter.prevent="() => {}"
      :model="contract"
      label-position="top"
      ref="contractForm"
    >
      <div class="form-group-wrapper">
        <!-- Name - Description-->

        <!-- Organization -->
        <div class="form-wrapper full">
          <el-form-item
            prop="organization.id"
            :rules="[
              {
                required: true,
                message: $t('COMMON.ORGANIZATION_REQUIRED'),
              },
            ]"
          >
            <base-input
              :label="`${$t('COMMON.ORGANIZATION')} (*)`"
              :placeholder="$t('COMMON.ORGANIZATION')"
              v-if="
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
                !hideOrganization
              "
            >
              <organization-selector
                :organization="contract.organization.id"
                :filterable="true"
                :showAll="false"
                @organizationChanged="
                  (organizationId) => {
                    contract.organization.id = organizationId;
                    onFormChanged();
                  }
                "
                :disabled="!!contract.id"
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.organization" />
          </el-form-item>
        </div>

        <!-- Type -->
        <!-- <el-form-item
          class="form-group-wrapper-item w-100"
          :label="`${$t('CONTRACTS.CONTRACT_TYPE')} (*)`"
          prop="contract_type"
          :rules="[
            {
              required: true,
              message: $t('CONTRACTS.TYPE_REQUIRED'),
            },
          ]"
        >
          <el-select
            :name="`${$t('CONTRACTS.TYPE_REQUIRED')}`"
            v-model="contract.contract_type"
            :placeholder="$t('CONTRACTS.TYPE_REQUIRED')"
          >
            <el-option
              v-for="(contract, val) in CONTRACT_TYPES"
              :key="val"
              :value="val"
              :label="`${val ? $t('CONTRACTS.' + val) : ''}`"
            />
          </el-select>
          <validation-error :errors="apiValidationErrors.contract_type" />
        </el-form-item> -->

        <!-- Name -->
        <el-form-item
          class="form-group-wrapper-item w-100"
          prop="name"
          :rules="[
            {
              required: true,
              message: $t('COMMON.NAME_REQUIRED'),
            },
          ]"
        >
          <base-input
            :label="`${$t('COMMON.NAME')} (*)`"
            :placeholder="$t('COMMON.NAME')"
          >
            <el-input
              :placeholder="$t('COMMON.NAME')"
              v-model="contract.name"
              @change="
                () => {
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.name" />
        </el-form-item>

        <!--  <el-form-item class="form-group-wrapper-item w-100" prop="undetermined">
          <el-checkbox
            v-model="contract.undetermined"
            :label="$t('CONTRACTS.UNDETERMINED')"
            border
          ></el-checkbox>
        </el-form-item>
        <el-form-item
          v-if="contract.undetermined"
          class="form-group-wrapper-item"
          :label="`${$t('CONTRACTS.DURATION')} (*)`"
          prop="duration"
        >
          <el-input
            type="number"
            :placeholder="$t('CONTRACTS.DURATION')"
            v-model="contract.duration"
          />
        </el-form-item> -->
        <!-- Excerpt -->
        <el-form-item
          class="form-group-wrapper-item w-100"
          :label="`${$t('COMMON.EXCERPT')} (*)`"
          prop="excerpt"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            :placeholder="$t('COMMON.EXCERPT')"
            v-model="contract.excerpt"
          >
          </el-input>
          <validation-error :errors="apiValidationErrors.excerpt" />
        </el-form-item>


        
        <!-- Roles -->
        <el-form-item
          class="form-group-wrapper-item w-100"
          prop="roles"
          :rules="[
            {
              required: true,
              message: $t('SIGNATURES.ROLES_REQUIRED'),
            },
          ]"
        >
          <base-input
            :label="`${$t('SIGNATURES.ROLES')} (*)`"
            :placeholder="$t('SIGNATURES.ROLES')"
          >
          <el-select
            v-model="contract.roles"
            multiple
            filterable
            allow-create
            default-first-option
            :placeholder="$t('SIGNATURES.ADD_ROLES')">
            <el-option
              v-for="(item, index) in defaultRoles"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.roles" />
        </el-form-item>


        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            @click.prevent="checkContractInformation"
            :disabled="loading || processing"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
            {{
              contract.id
                ? $t("CONTRACTS.EDIT_CONTRACT")
                : $t("CONTRACTS.ADD_CONTRACT")
            }}
          </base-button>
        </div>
      </div>
    </el-form>

    <modal
      :show="showContractCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("CONTRACTS.SAME_CONTRACTS", {
                name: contract.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="contracts"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.name}` }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('COMMON.EXCERPT')" min-width="300">
                <template v-slot="{ row }">
                  {{ row.excerpt ?? "" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleContractCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Option,
  Select,
  Table,
  TableColumn,
  Form,
  FormItem,
  Checkbox,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { CONTRACT_TYPES, DEFAULT_ROLES } from "@/constants/contracts";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
export default {
  layout: "DashboardLayout",

  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    OrganizationSelector,
  },

  mixins: [formMixin],

  props: [
    "hideOrganization",
    "contractData",
    "formErrors",
    "loading",
    "hideSubmit",
  ],
  data() {
    let contractData = { ...this.contractData };
    contractData = this.$fillUserOrganizationData(contractData);
    return {
      contract: contractData,
      contracts: [],
      total: 0,
      showModal: false,
      showContractCheckModal: false,
      processing: false,
      CONTRACT_TYPES: CONTRACT_TYPES,
      defaultRoles:DEFAULT_ROLES,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit() {
      const checkUniqueContract = await this.checkContractUniqueInformation();
      if (checkUniqueContract) {
        await this.sendInformation();
      } else {
        this.showContractCheckModal = true;
      }
    },

    async sendInformation() {
      if (this.contract.name) {
        this.contract.name = this.contract.name.toString();
      }
      let contractData = cloneDeep(this.contract);
      contractData = this.$fillUserOrganizationData(contractData);
      this.$emit("contractSubmitted", contractData);
    },

    cancelEdition() {
      this.$emit("cancelEdition");
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      if (!this.contract?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.contract?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };
        await this.$store.dispatch("contracts/list", params);
        this.contracts = this.$store.getters["contracts/list"];
        this.total = this.$store.getters["contracts/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkContractUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.contract?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleContractCheckModalState();
      await this.sendInformation();
      this.processing = false;
    },

    async toggleContractCheckModalState() {
      this.showContractCheckModal = !this.showContractCheckModal;
    },

    checkContractInformation() {
      this.$refs["contractForm"].validate(async (valid) => {
        if (valid) {
          if (this.contract.name) {
            this.handleSubmit();
            this.showModal = false;
          } else {
            this.showModal = true;
          }
        }
      });
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    contractData(contractData) {
      if (contractData) {
        this.contract = {
          ...this.contract,
          ...cloneDeep(contractData),
        };
      }
    },
  },
};
</script>

<style>
.add-form .btn-cancel {
  font-size: 14px;
  min-height: 40px;
  border-radius: 8px;
  padding: 0 15px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}

.add-form .el-input__inner,
.add-form .el-textarea {
  background-color: transparent;
  border-radius: 8px;
  color: #000;
}
.form-group-wrapper-item .el-select {
  width: 100%;
}
.form-group-wrapper-item label {
  margin-bottom: 0.1rem !important;
}
.form-group-wrapper-item .el-form-item__label {
  padding: 0 0 1px !important;
}
</style>
